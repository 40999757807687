import { graphql } from 'gatsby'
import React from 'react'
import Container from '../components/container'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Partner from '../components/partner'
import { PageTitle } from '../components/page-title'

export const query = graphql`
  query PartnersQuery {
    strapiGraphql {
      partner {
        pageTitle
        pageSeo {
          keywords
          title
          description
        }
        items {
          title
          description
          id
          logo {
            mediaFile {
              publicURL
            }
            url
          }
        }
      }
    }
  }
`

export default function Partners({ data }) {
  const { pageTitle, items, pageSeo } = data.strapiGraphql.partner
  return (
    <Layout>
      <SEO {...pageSeo} />
      <Container>
        <PageTitle>{pageTitle}</PageTitle>
        {items.map(({ id, ...rest }) => (
          <Partner key={id} {...rest} />
        ))}
      </Container>
    </Layout>
  )
}
