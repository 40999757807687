import styled from 'styled-components'
import { spacing, device } from '../../utils/mixins'

export const StyledPageTitle = styled.h1`
  color: #222222;
  font: 400 18px/25px Tenor, sans-serif;
  margin: ${spacing(3, 0, 0)};

  @media ${device.tabletLand}, ${device.desktop} {
    font-size: 36px;
    line-height: 40px;
    margin-top: ${spacing(5)};
  }
`
