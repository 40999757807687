import styled from 'styled-components'
import { spacing, device } from '../../utils/mixins'

export const StyledContainer = styled.div`
  border: 4px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  padding: ${spacing(3, 2)};
  margin-top: ${spacing(2)};

  @media ${device.tablet}, ${device.tabletLand}, ${device.desktop} {
    flex-direction: row;
    margin-top: 0;
    border-top: none;
    &:first-of-type {
      border-top: 4px solid #e0e0e0;
      margin-top: ${spacing(2)};
    }
  }

  @media ${device.tabletLand}, ${device.desktop} {
    padding: ${spacing(4)};

    &:first-of-type {
      margin-top: ${spacing(3)};
    }
  }
`

export const StyledImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.tablet}, ${device.tabletLand}, ${device.desktop} {
    align-items: flex-start;
    justify-content: flex-start;
    flex: 0 0 150px;
    max-width: 150px;
  }

  img {
    max-width: 100%;
  }
`

export const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${spacing(4)};

  @media ${device.tablet}, ${device.tabletLand}, ${device.desktop} {
    margin-top: 0;
    padding-left: ${spacing(4)};
  }

  @media ${device.tabletLand}, ${device.desktop} {
    padding-left: ${spacing(9)};
  }

  h3 {
    color: #0074b8;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    margin: 0;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    margin: ${spacing(1, 0, 0)};

    @media ${device.tabletLand}, ${device.desktop} {
      font-size: 18px;
    }
  }
`
