import React from 'react'
import PropTypes from 'prop-types'
import Markdown from 'react-markdown'
import {
  StyledContainer,
  StyledImageContainer,
  StyledTextContainer,
} from './styledComponents'

const Partner = ({ title, description, logo }) => {
  return (
    <StyledContainer>
      <StyledImageContainer>
        <img src={logo.mediaFile.publicURL} alt={title} />
      </StyledImageContainer>
      <StyledTextContainer>
        <h3>{title}</h3>
        <Markdown>{description}</Markdown>
      </StyledTextContainer>
    </StyledContainer>
  )
}

Partner.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  logo: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default Partner
